@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif !important;
  background-color: #ffffff;
  font-weight: 400;
}
@layer base {
  .primary_heading {
    @apply font-manrope text-2xl sm:text-3xl lg:text-4xl xl:text-5xl custom-2xl:text-[54px] font-bold !mb-6 !mb-10 !mb-[60px];
  }

  .sub_heading {
    @apply text-base sm:text-lg lg:text-2xl custom-2xl:text-[32px] font-semibold font-manrope;
  }

  .common_para {
    @apply text-sm custom-2xl:text-lg opacity-70;
  }

  .common_link {
    @apply capitalize text-sm lg:text-lg duration-300 font-semibold;
  }

  .dark-icon path {
    stroke: black;
  }

  .active_link path {
    stroke: #499bfc;
    fill: #499bfc;
  }
  .active_link rect {
    stroke: #499bfc;
  }

  .profile_btn {
    @apply py-2 lg:py-4 px-4 lg:px-8 border-blueberry border rounded-lg text-white hover:text-blueberry bg-blueberry hover:bg-white text-base md:text-xl font-semibold duration-300;
  }
}

.font-manrope {
  font-family: "Manrope", sans-serif !important;
}
.loader {
  border-top-color: transparent;
}
.custom_container {
  @apply max-w-[1624px];
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.custom-checkbox {
  appearance: none;
  background-color: #fff;
  border: 1px solid #4978fc;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-image: url("./assets/images/svg/tick-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.custom-checkbox:checked {
  background-color: #4978fc;
}

.custom-checkbox:checked::before {
  opacity: 1;
}

::-webkit-scrollbar-thumb {
  background: #499bfc;
  border-radius: 10px;
}
.category_scrollbar::-webkit-scrollbar {
  width: 2px;
}
.category_scrollbar::-webkit-scrollbar-thumb {
  background: #a5a5a5;
}
@media (max-width: 1599.98px) {
  .custom_container {
    @apply max-w-[1360px];
  }
}

@media (max-width: 1499.98px) {
  .custom_container {
    @apply max-w-[1164px];
  }
}

@media (max-width: 1023.98px) {
  .custom_container {
    @apply max-w-[760px];
  }
}
